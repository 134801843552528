import React from "react";
import { Link } from "react-router-dom";

import "./ProjectCard.css";
import "./QueryProjectCard.css";

const ProjectCard = ({ project }) => {
  const customStyle = {
    "--clr": "#000",
  };

  return (
    <div className="card">
      <div className="card-img">
        <img src={project.image} alt={project.title} className="card-image" />
      </div>
      <div className="card-body">
        <h5 className="card-title">{project.title}</h5>
        <p className="card-text">{project.description}</p>
        <Link to={project.path} style={customStyle}>
          {" "}
          <span>Explore</span>
          <i></i>
        </Link>
      </div>
    </div>
  );
};

export default ProjectCard;
