// import Data from "../../../Assets/Videos/Video1.mp4";

export const Business_Proj_Data = [
  {
    Proj_Title: "Uploading...",
    Proj_Disc: "Uploading...",
    Proj_Img: "",
    Proj_path: "https://www.manthya.com",
  },
  {
    Proj_Title: "Uploading...",
    Proj_Disc: "Uploading...",
    Proj_Img: "",
    Proj_path: "https://www.manthya.com",
  },
  {
    Proj_Title: "Uploading...",
    Proj_Disc: "Uploading...",
    Proj_Img: "",
    Proj_path: "https://www.manthya.com",
  },
  {
    Proj_Title: "Uploading...",
    Proj_Disc: "Uploading...",
    Proj_Img: "",
    Proj_path: "https://www.manthya.com",
  },
  {
    Proj_Title: "Uploading...",
    Proj_Disc: "Uploading...",
    Proj_Img: "",
    Proj_path: "https://www.manthya.com",
  },
  {
    Proj_Title: "Uploading...",
    Proj_Disc: "Uploading...",
    Proj_Img: "",
    Proj_path: "https://www.manthya.com",
  },
];
