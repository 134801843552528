import LinkedIn from "../../../Assets/SocialMediaIcons/linkedin.png";
import GitHub from "../../../Assets/SocialMediaIcons/github.png";
import Instagram from "../../../Assets/SocialMediaIcons/instagram.png";
import WhatsApp from "../../../Assets/SocialMediaIcons/whatsapp.png";
import Gmail from "../../../Assets/SocialMediaIcons/gmail.png";
import Facebook from "../../../Assets/SocialMediaIcons/facebook.png";

export const SocialMediaData = [
  {
    Media_Img: LinkedIn,
    title: "LinkedIn",
    to: "https://www.linkedin.com/in/manoj-c-n-b50273198/",
  },
  {
    Media_Img: GitHub,
    title: "GitHub",
    to: "https://github.com/ManojCN20",
  },
  {
    Media_Img: Gmail,
    title: "G-mail",
    to: "mailto:manojcn0302@gmail.com",
  },
  {
    Media_Img: WhatsApp,
    title: "WhatsApp",
    to: "https://wa.me/917353641419",
  },
  {
    Media_Img: Facebook,
    title: "Facebook",
    to: "https://www.facebook.com/manoj.cn.752/",
  },
  {
    Media_Img: Instagram,
    title: "Instagram",
    to: "https://instagram.com/manuinchu",
  },
];
