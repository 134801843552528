import React, { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import "./AboutSection.css";
import "./QueryAboutSection.css";
import AboutImg from "../../../Assets/Images/AboutImg.jpg";
import CV from "../../../Assets/Files/Manoj Resume Main.pdf";
import { Link } from "react-router-dom";

const AboutSection = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <section className="about_section">
      <h2 className="section__title">My Introduction </h2>

      <div className="about__container" data-aos="fade-right">
        <img src={AboutImg} alt="My_Image" className="about__img" />

        <div className="about__data" data-aos="zoom-in">
          <p className="about__description">
            As a highly skilled and motivated professional with overall 2 years
            of work experience, I bring a versatile skill set encompassing both
            Data Analysis and Frontend Development. Proficient in Python, Excel,
            SQL, Tableau, HTML, CSS, JavaScript, React, and Git/Github, my
            expertise extends to data analysis, modeling, and visualization, as
            well as frontend development. I have a proven track record of
            contributing to improved user interfaces, enhancing predictive
            outcomes, and fostering business growth. With a background in
            guiding and instructing others, I excel in research, development,
            and the implementation of functional enhancements. <br /> My strong
            communication skills enable me to effectively resolve issues and
            collaborate with teams, ultimately optimizing customer satisfaction.
          </p>

          <a download="" href={CV} className="btn">
            <span>Download CV</span>
            <i></i>
          </a>
          <Link to="/about" className="btn2">
            <span>Know More</span>
            <i></i>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
