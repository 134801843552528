import Portfolio from "../../../Assets/Videos/Portfolio.mp4";
import PizzaMenu from "../../../Assets/Videos/Pizza_Menu.mp4";
import Steps from "../../../Assets/Videos/Steps.mp4";

// Project Videos Width = 1470 * 676
export const Dev_Proj_Data = [
  {
    Proj_Title: "My Portfolio Project",
    Proj_Disc:
      "Welcome to my portfolio! This space showcases my passion and expertise in Development and Analysis. Explore my collection of projects that reflect my skills, and learn more about my professional journey. From innovative solutions to creative endeavors, each project represents a piece of my commitment to excellence. Thank you for visiting, and feel free to reach out for collaboration or inquiries.",
    Proj_Img: Portfolio,
    Proj_path: "https://www.manojcn-portfolio.online",
  },
  {
    Proj_Title: "Pizza Menu",
    Proj_Disc:
      "This project provides a comprehensive introduction to fundamental React concepts, including the creation of components, handling props, destructuring props, rendering lists, and implementing conditional rendering using 'AND', ternary, and multiple return operators. Topics covered also include working with lists, arrays, objects, and utilizing the map method for efficient data handling.",
    Proj_Img: PizzaMenu,
    Proj_path: "https://pizza-menu-proj.netlify.app/",
  },
  {
    Proj_Title: "Steps",
    Proj_Disc:
      "This concise project explores fundamental concepts of states and event handling in React, crucial for creating dynamic web pages. It provides a brief yet essential introduction to these key concepts.",
    Proj_Img: Steps,
    Proj_path: "https://steps-react-proj.netlify.app/",
  },
  {
    Proj_Title: "Uploading...",
    Proj_Disc: "Uploading...",
    Proj_Img: "",
    Proj_path: "https://www.manthya.com",
  },
  {
    Proj_Title: "Uploading...",
    Proj_Disc: "Uploading...",
    Proj_Img: "",
    Proj_path: "https://www.manthya.com",
  },
  {
    Proj_Title: "Uploading...",
    Proj_Disc: "Uploading...",
    Proj_Img: "",
    Proj_path: "https://www.manthya.com",
  },
];
