import React, { useEffect } from "react";
import Typewriter from "typewriter-effect";

import AOS from "aos";
import "aos/dist/aos.css";
import "./DevProjects.css";
import "./QueryDevProjects.css";
import Dev_Img from "../../../Assets/Images/Web_Development.jpg";
import ProjectShowCard from "../../Projects/Cards/ProjectShowCard";
import { Dev_Proj_Data } from "../DevProjects/DevProjData";

const DevProjects = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    // Scroll to the top on component mount (initial render)
    window.scrollTo(0, 0);
    // Scroll to the top on page reload
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className="dev_projects">
      <section className="dev_header">
        <div className="dev_content">
          <div className="dev_img">
            <img src={Dev_Img} alt="Mountain_Img" />
          </div>
          <div className="dev_heading">
            <h1>
              <span>
                <Typewriter
                  options={{
                    autoStart: true,
                    loop: true,
                    delay: 80,
                    strings: ["Take a Look at My Development Projects...!"],
                  }}
                />
              </span>
            </h1>
          </div>
        </div>
      </section>
      <section className="dev_main">
        <h1 className="dev_main_title">Explore My Development Projects</h1>
        <div className="dev_proj">
          {Dev_Proj_Data.map((Proj_Data, index) => (
            <ProjectShowCard key={index} Proj_Data={Proj_Data} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default DevProjects;
