import React, { useEffect } from "react";
import Typewriter from "typewriter-effect";
import ProjectCard from "./Cards/ProjectCard";
import { projects } from "./projectsData";

import AOS from "aos";
import "aos/dist/aos.css";
import "../../App.css";
import "./Projects.css";
import "./QueryProjects.css";
import Project_img from "../../Assets/Images/Road.jpeg";

const Projects = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    // Scroll to the top on component mount (initial render)
    window.scrollTo(0, 0);
    // Scroll to the top on page reload
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className="projects">
      <section className="skills_header">
        <div className="head_content">
          <div className="head_img">
            <img src={Project_img} alt="head_Img" />
          </div>
          <div className="head_heading">
            <h1>
              <span>
                <Typewriter
                  options={{
                    autoStart: true,
                    loop: true,
                    delay: 80,
                    strings: ["My Projects...!"],
                  }}
                />
              </span>
            </h1>
          </div>
        </div>
      </section>
      <div className="projects_names" data-aos="zoom-in">
        {projects.map((project, index) => (
          <ProjectCard key={index} project={project} />
        ))}
      </div>
    </div>
  );
};

export default Projects;
