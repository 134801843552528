import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import Header from "./Components/Header/Header";
import Projects from "./Components/Projects/Projects";
import Home from "./Components/Home/Home";
import Contact from "./Components/Contact/Contact";
import Footer from "./Components/Footer/Footer";
import About from "./Components/About/About";
import Skills from "./Components/Skills/Skills";
import DevProjects from "./Components/Projects/DevProjects/DevProjects";
import DataProjects from "./Components/Projects/DataProjects/DataProjects";
import BusinessProjects from "./Components/Projects/BusinessProjects/BusinessProjects";
import ScrollUp from "./Components/scrollup/ScrollUp";
import MyBlog from "./Components/MyBlog/MyBlog";

function App() {
  return (
    <BrowserRouter>
      <div className="app">
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/about" element={<About />}></Route>
          <Route exact path="/skills" element={<Skills />}></Route>
          <Route exact path="/projects" element={<Projects />}></Route>
          <Route exact path="/contact" element={<Contact />}></Route>
          <Route exact path="/devProjects" element={<DevProjects />}></Route>
          <Route exact path="/dataProjects" element={<DataProjects />}></Route>
          <Route
            exact
            path="/businessProjects"
            element={<BusinessProjects />}
          ></Route>
          <Route exact path="/myblog" element={<MyBlog />}></Route>
        </Routes>
        <Footer />
        <ScrollUp />
      </div>
    </BrowserRouter>
  );
}

export default App;
