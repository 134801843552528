import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";
import { SocialMediaData } from "./SocialMediaData";

import AOS from "aos";
import "aos/dist/aos.css";
import "./ProfileSection.css";
import "./QueryProfileSection.css";
import Profile_Img from "../../../Assets/Images/profile_img.png";

const ProfileSection = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <section className="profile">
      <div className="profile_container">
        <div className="profile_content">
          <div className="profile_details">
            <h1 className="profile_name" data-aos="fade-right">
              <span>M</span>anoj <span>C</span> N
            </h1>

            <h3 className="profile_designation">
              <Typewriter
                options={{
                  autoStart: true,
                  loop: true,
                  delay: 80,
                  strings: ["Data Analyst & Frontend Developer...!"],
                }}
              />
            </h3>
            <p className="profile_summary" data-aos="fade-left">
              Versatile professional skilled in extracting actionable insights
              through data analysis while creating seamless and intuitive user
              experiences as a frontend developer.
            </p>
            <div className="hello_btn" data-aos="fade-up">
              <Link className="btn-contact" to="/contact">
                <span>Say Hello</span>
                <i></i>
              </Link>
            </div>
            <div className="social_media" data-aos="fade-up">
              {SocialMediaData.map((data, index) => {
                return (
                  <div key={index}>
                    <Link to={data.to} target="_blank" rel="noreferrer">
                      <img src={data.Media_Img} alt="SocialMedia" />
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="profile_img" data-aos="zoom-in">
            <img src={Profile_Img} alt="Profile_Image" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfileSection;
