import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import "./Header.css";
import "./QueryHeader.css";
import Name_Logo from "../../Assets/Images/Logo_Manoj_1.png";

const Header = () => {
  const [Toggle, showMenu] = useState(false);

  return (
    <>
      <section className="header">
        <div className="header_container">
          <nav className="nav_bar">
            <Link to="/" className="nav_link">
              <img src={Name_Logo} alt="Name_logo" />
              <h1>Manoj C N</h1>
            </Link>
            {/* {Toggle ? "nav__menu show-menu" : "nav__menu"} */}
            <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
              <ul className="nav_list">
                <li>
                  <NavLink
                    to="/myblog"
                    activeclassname="active"
                    onClick={() => showMenu(!Toggle)}
                  >
                    My Blog
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/"
                    exact = "true"
                    activeclassname="active"
                    onClick={() => showMenu(!Toggle)}
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/about"
                    activeclassname="active"
                    onClick={() => showMenu(!Toggle)}
                  >
                    About
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/skills"
                    activeclassname="active"
                    onClick={() => showMenu(!Toggle)}
                  >
                    Skills
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/projects"
                    activeclassname="active"
                    onClick={() => showMenu(!Toggle)}
                  >
                    Projects
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/contact"
                    activeclassname="active"
                    onClick={() => showMenu(!Toggle)}
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
              <i
                className="uil uil-times nav__close"
                onClick={() => showMenu(!Toggle)}
              ></i>
            </div>

            <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
              <i className="uil uil-apps"></i>
            </div>
          </nav>
        </div>
      </section>
    </>
  );
};

export default Header;
