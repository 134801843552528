import React, { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import "./SkillsSection.css";
import "./QuerySkillsSection.css";
import { Link } from "react-router-dom";

const SkillsSection = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <section className="skills_section">
      <div className="skills_title">
        <h1>My Technical Skills</h1>
      </div>
      <div className="skills_container">
        <div className="skill_box_container">
          <div className="skills_data skills_box">
            <div className="skills_data_title" data-aos="fade-down">
              <h1>Data Analysis</h1>
            </div>
            <div className="skills_info" data-aos="zoom-in">
              <div className="skill">
                <p>Excel</p>
                <div className="level_info">
                  <div className="skill_experience">
                    <i className="bx bx-award skill_icon"></i>
                    <p>Advanced</p>
                  </div>
                  <div className="skill_experience">
                    <i className="bx bx-briefcase-alt skill_icon"></i>
                    <p>2 years</p>
                  </div>
                </div>
              </div>
              <div className="skill">
                <p>Python</p>
                <div className="level_info">
                  <div className="skill_experience">
                    <i className="bx bx-award skill_icon"></i>
                    <p>Intermediate</p>
                  </div>
                  <div className="skill_experience">
                    <i className="bx bx-briefcase-alt skill_icon"></i>
                    <p>1 year</p>
                  </div>
                </div>
              </div>
              <div className="skill">
                <p>SQL</p>
                <div className="level_info">
                  <div className="skill_experience">
                    <i className="bx bx-award skill_icon"></i>
                    <p>Intermediate</p>
                  </div>
                  <div className="skill_experience">
                    <i className="bx bx-briefcase-alt skill_icon"></i>
                    <p>1 year</p>
                  </div>
                </div>
              </div>
              <div className="skill">
                <p>Tableau</p>
                <div className="level_info">
                  <div className="skill_experience">
                    <i className="bx bx-award skill_icon"></i>
                    <p>Intermediate</p>
                  </div>
                  <div className="skill_experience">
                    <i className="bx bx-briefcase-alt skill_icon"></i>
                    <p>1 year</p>
                  </div>
                </div>
              </div>
              <div className="skill">
                <p>Data Analysis</p>
                <div className="level_info">
                  <div className="skill_experience">
                    <i className="bx bx-award skill_icon"></i>
                    <p>Intermediate</p>
                  </div>
                  <div className="skill_experience">
                    <i className="bx bx-briefcase-alt skill_icon"></i>
                    <p>1 year</p>
                  </div>
                </div>
              </div>
              <div className="skill">
                <p>Data Visualization</p>
                <div className="level_info">
                  <div className="skill_experience">
                    <i className="bx bx-award skill_icon"></i>
                    <p>Intermediate</p>
                  </div>
                  <div className="skill_experience">
                    <i className="bx bx-briefcase-alt skill_icon"></i>
                    <p>1 year</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="skills_dev skills_box">
            <div className="skills_data_title" data-aos="fade-down">
              <h1>Web Development</h1>
            </div>
            <div className="skills_info" data-aos="zoom-in">
              <div className="skill">
                <p>HTML</p>
                <div className="level_info">
                  <div className="skill_experience">
                    <i className="bx bx-award skill_icon"></i>
                    <p>Intermediate</p>
                  </div>
                  <div className="skill_experience">
                    <i className="bx bx-briefcase-alt skill_icon"></i>
                    <p>1 year</p>
                  </div>
                </div>
              </div>
              <div className="skill">
                <p>CSS</p>
                <div className="level_info">
                  <div className="skill_experience">
                    <i className="bx bx-award skill_icon"></i>
                    <p>Intermediate</p>
                  </div>
                  <div className="skill_experience">
                    <i className="bx bx-briefcase-alt skill_icon"></i>
                    <p>1 year</p>
                  </div>
                </div>
              </div>
              <div className="skill">
                <p>JavaScript</p>
                <div className="level_info">
                  <div className="skill_experience">
                    <i className="bx bx-award skill_icon"></i>
                    <p>Intermediate</p>
                  </div>
                  <div className="skill_experience">
                    <i className="bx bx-briefcase-alt skill_icon"></i>
                    <p>1 year</p>
                  </div>
                </div>
              </div>
              <div className="skill">
                <p>React</p>
                <div className="level_info">
                  <div className="skill_experience">
                    <i className="bx bx-award skill_icon"></i>
                    <p>Intermediate</p>
                  </div>
                  <div className="skill_experience">
                    <i className="bx bx-briefcase-alt skill_icon"></i>
                    <p>1 year</p>
                  </div>
                </div>
              </div>
              <div className="skill">
                <p>SQL</p>
                <div className="level_info">
                  <div className="skill_experience">
                    <i className="bx bx-award skill_icon"></i>
                    <p>Intermediate</p>
                  </div>
                  <div className="skill_experience">
                    <i className="bx bx-briefcase-alt skill_icon"></i>
                    <p>1 year</p>
                  </div>
                </div>
              </div>
              <div className="skill">
                <p>Git / GitHub</p>
                <div className="level_info">
                  <div className="skill_experience">
                    <i className="bx bx-award skill_icon"></i>
                    <p>Intermediate</p>
                  </div>
                  <div className="skill_experience">
                    <i className="bx bx-briefcase-alt skill_icon"></i>
                    <p>1 year</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="know_btn" data-aos="fade-up">
          <Link to="/skills" className="btn3">
            <span>Know More</span>
            <i></i>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default SkillsSection;
