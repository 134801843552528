import React, { useEffect } from "react";
import Typewriter from "typewriter-effect";

import "./MyBlog.css";
import "./QueryMyBlog.css";
import Blog_Img from "../../Assets/Images/Road.jpeg";

const MyBlog = () => {
  useEffect(() => {
    // Scroll to the top on component mount (initial render)
    window.scrollTo(0, 0);
    // Scroll to the top on page reload
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className="my_blog">
      <section className="blog_header">
        <div className="head_content">
          <div className="head_img">
            <img src={Blog_Img} alt="blog_Img" />
          </div>
          <div className="head_heading">
            <h1>
              <span>
                <Typewriter
                  options={{
                    autoStart: true,
                    loop: true,
                    delay: 80,
                    strings: ["My Blog...!"],
                  }}
                />
              </span>
            </h1>
          </div>
        </div>
      </section>
      <section className="blog_main">
        <h1>
          <span>
            <Typewriter
              options={{
                autoStart: true,
                loop: true,
                delay: 80,
                strings: ["THIS SECTION IS UNDER DEVELOPMENT...."],
              }}
            />
          </span>
        </h1>
      </section>
    </div>
  );
};

export default MyBlog;
