import React, { useEffect } from "react";
import Typewriter from "typewriter-effect";

import "./BusinessProjects.css";
import "./QueryBusinessProjects.css";
import Business_Img from "../../../Assets/Images/Business_Analytics.jpg";
import ProjectShowCard from "../../Projects/Cards/ProjectShowCard";
import { Business_Proj_Data } from "../BusinessProjects/BusinessProjData";

const BusinessProjects = () => {
  useEffect(() => {
    // Scroll to the top on component mount (initial render)
    window.scrollTo(0, 0);
    // Scroll to the top on page reload
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className="business_projects">
      <section className="business_header">
        <div className="business_content">
          <div className="business_img">
            <img src={Business_Img} alt="Mountain_Img" />
          </div>
          <div className="business_heading">
            <h1>
              <span>
                <Typewriter
                  options={{
                    autoStart: true,
                    loop: true,
                    delay: 80,
                    strings: [
                      "Take a Look at My Business Analytics Projects...!",
                    ],
                  }}
                />
              </span>
            </h1>
          </div>
        </div>
      </section>
      <section className="business_main">
        <h1 className="dev_main_title">
          Explore My Business Analytics Projects
        </h1>
        <div className="business_proj">
          {Business_Proj_Data.map((Proj_Data, index) => (
            <ProjectShowCard key={index} Proj_Data={Proj_Data} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default BusinessProjects;
