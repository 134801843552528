import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

import AOS from "aos";
import "aos/dist/aos.css";
import "./ContactForm.css";
import "./QueryContactForm.css";

const ContactForm = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_rpfwt6s",
      "template_eue621e",
      form.current,
      "I8uCwj08CD-Mb9Mk2"
    );
    alert("Message Sent! Thank you and Will get back to you ASAP!");

    e.target.reset();
  };

  return (
    <section className="contact_form">
      <h2 className="section_title">Contact Me</h2>

      <div className="contact__container container grid" data-aos="fade-down">
        <div className="contact__content contact_form1">
          <h3 className="contact__title">Write Me Your Project</h3>

          <form ref={form} onSubmit={sendEmail} className="contact__form">
            <div className="contact__form-div">
              <label htmlFor="name" className="contact__form-tag">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="contact__form-input"
                placeholder="Insert Your Name"
                required
              />
            </div>
            <div className="contact__form-div">
              <label htmlFor="email" className="contact__form-tag">
                Mail
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="contact__form-input"
                placeholder="Insert Your Email"
                required
              />
            </div>
            <div className="contact__form-div">
              <label htmlFor="phoneNumber" className="contact__form-tag">
                Phone Number
              </label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                className="contact__form-input"
                placeholder="WhatsApp Number with Country Code"
                required
              />
            </div>

            <div className="contact__form-div contact__form-area">
              <label className="contact__form-tag">Project</label>
              <textarea
                name="project"
                cols="30"
                rows="10"
                className="contact__form-input"
                placeholder="Write Your Project"
                required
              ></textarea>
            </div>

            <button className="button" data-aos="fade-up">
              <span>Send Message</span>
              <i></i>
            </button>
          </form>
        </div>

        <div className="contact__content">
          <h3 className="contact__title">Talk To Me</h3>

          <div className="contact__info">
            <div className="contact__card" data-aos="fade-right">
              <i className="bx bx-mail-send contact__card-icon"></i>

              <h3 className="contact__card-title">Email</h3>
              <span className="contact__card-data">manojcn0302@gmail.com</span>

              <Link
                to="mailto:manojcn0302@gmail.com"
                className="contact__button"
              >
                Write me{" "}
                <i className="bx bx-right-arrow-alt contact__button-icon"></i>
              </Link>
            </div>

            <div className="contact__card" data-aos="zoom-in">
              <i className="bx bxl-whatsapp contact__card-icon"></i>

              <h3 className="contact__card-title">WhatsApp</h3>
              <span className="contact__card-data">+91-7353641419</span>

              <Link
                to="https://wa.me/917353641419"
                className="contact__button"
                target="_blank"
                rel="noreferrer"
              >
                Write me{" "}
                <i className="bx bx-right-arrow-alt contact__button-icon"></i>
              </Link>
            </div>

            <div className="contact__card" data-aos="fade-left">
              <i className="bx bxl-messenger contact__card-icon"></i>

              <h3 className="contact__card-title">Messenger</h3>
              <span className="contact__card-data">Manoj C N</span>

              <Link
                to="https://m.me/manoj.cn.752"
                className="contact__button"
                target="_blank"
                rel="noreferrer"
              >
                Write me{" "}
                <i className="bx bx-right-arrow-alt contact__button-icon"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
