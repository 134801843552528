import React, { useEffect, useState } from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import "./QualificationSection.css";
import "./QueryQualificationSection.css";

const QualificationSection = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <section className="qualification_section">
      <h2 className="section__title">My Personal Journey!</h2>

      <div className="qualification__container container">
        <div className="qualification__tabs">
          <div
            className={
              toggleState === 1
                ? "qualification__button qualification__active button--flex"
                : "qualification__button button--flex"
            }
            onClick={() => toggleTab(1)}
          >
            <i className="uil uil-briefcase-alt qualification__icon"></i>
            Experience
          </div>
          <div
            className={
              toggleState === 2
                ? "qualification__button qualification__active button--flex"
                : "qualification__button button--flex"
            }
            onClick={() => toggleTab(2)}
          >
            <i className="uil uil-graduation-cap qualification__icon"></i>
            Education
          </div>
        </div>

        <div className="qualification__sections">
          <div
            className={
              toggleState === 1
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <div className="qualification__data">
              <div className="qualification__box3" data-aos="fade-right">
                <h3 className="qualification__title">Process Associate</h3>
                <span className="qualification__subtitle">
                  Flatworld Mortgage Processing Pvt. Ltd. - Bengaluru,
                  Karnataka, India
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calender-alt"></i> Oct 2021 - Nov 2022
                  (1 Year)
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div className="qualification__box4" data-aos="fade-right">
                <h3 className="qualification__title">
                  Trainee Data Analyst & Frontend Developer
                </h3>
                <span className="qualification__subtitle">
                  TECH I.S. India Pvt. Ltd. - Bengaluru, Karnataka, India
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calender-alt"></i> Nov 2022 - Dec 2023
                  (1 Year)
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div className="qualification__box5" data-aos="fade-right">
                <h3 className="qualification__title">
                  Freelancer <br />( Portfolio Development, Data and Business
                  Analytics & Website Development)
                </h3>
                <span className="qualification__subtitle">
                  Manthya Portfolio and Analytics - Mandya, Karnataka, India
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calender-alt"></i> Dec 2023 - Present
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
          </div>
          <div
            className={
              toggleState === 2
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <div className="qualification__data">
              <div className="qualification__box1" data-aos="fade-right">
                <h3 className="qualification__title">10th STD</h3>
                <span className="qualification__subtitle">
                  Karnataka Education Board
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i> 2014
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div className="qualification__box2" data-aos="fade-left">
                <h3 className="qualification__title">11th - 12th</h3>
                <span className="qualification__subtitle">
                  Karnataka Secondary Education Board
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i> 2014 - 2016
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div className="qualification__box6" data-aos="fade-right">
                <h3 className="qualification__title">
                  Bachelor of Engineering (Mechanical)
                </h3>
                <span className="qualification__subtitle">
                  Visvesvaraya Technological University
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i> 2016 - 2021
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QualificationSection;
