import React, { useEffect } from "react";

import "./Home.css";
import ProfileSection from "./ProfileSection/ProfileSection";
import AboutSection from "./AboutSection/AboutSection";
import SkillsSection from "./SkillsSection/SkillsSection";
import ServiceSection from "./ServiceSection/ServiceSection";
import QualificationSection from "./QualificationSection/QualificationSection";
import ContactSection from "./ContactSection/ContactSection";
import ContactForm from "../Contact/ContactForm";

const Home = () => {
  useEffect(() => {
    // Scroll to the top on component mount (initial render)
    window.scrollTo(0, 0);
    // Scroll to the top on page reload
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className="Home">
      <ProfileSection />
      <AboutSection />
      <SkillsSection />
      <ServiceSection />
      <QualificationSection />
      <ContactSection />
      <ContactForm />
    </div>
  );
};

export default Home;
