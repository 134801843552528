import React, { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import "./ContactSection.css";
import "./QueryContactSection.css";

const ContactSection = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <>
      <section className="bottom_section" data-aos="zoom-in">
        <div className="bottom_box">
          <div className="bottom_container">
            <div className="bottom_data">
              <h1>Let's Work Together On Your Next Project..!</h1>
              <p>
                Collaborate with me on your next project in data analysis and
                frontend development, where my expertise meets your vision.
                Together, we'll seamlessly blend data-driven insights with
                cutting-edge frontend design to elevate your project to new
                heights. <br /> <br /> Let's turn your ideas into a reality by
                combining analytical precision with captivating user
                experiences.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactSection;
