import React, { useEffect, useState } from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import "./ServiceSection.css";
import "./QueryServiceSection.css";
import { Link } from "react-router-dom";

const ServiceSection = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const [toggleState, setToggleState] = useState(0);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <section className="service_section">
      <div className="service__title">
        <h1>How Can I Help You?</h1>
      </div>
      <div className="services__container" data-aos="zoom-in">
        <div className="services__content">
          <i className="uil uil-edit services__icon"></i>
          <h3 className="services__title">
            Analytics <br /> Data and Business
          </h3>

          <span className="services__button" onClick={() => toggleTab(3)}>
            View More
            <i className="uil uil-arrow-right services__button-icon"></i>
          </span>

          <div
            className={
              toggleState === 3
                ? "services__modal active-modal"
                : "services__modal"
            }
          >
            <div className="services__modal-content">
              <i
                onClick={() => toggleTab(0)}
                className="uil uil-times services__modal-close"
              ></i>

              <h3 className="services__modal-title">
                Data and Business Analytics
              </h3>
              <p className="services__modal-description">
                Empower your decision-making with our Data and Business
                Analytics service. Uncover valuable insights, drive strategic
                actions, and optimize performance through advanced data
                analysis, fostering informed and impactful business decisions.
              </p>

              <ul className="services__modal-services grid">
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Harness our Data and Business Analytics service to empower
                    your decision-making with actionable insights.
                  </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Drive strategic actions by uncovering valuable and timely
                    insights from comprehensive data analysis.
                  </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Achieve optimal performance as our analytics service helps
                    fine-tune and enhance your business operations.
                  </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Make informed and impactful decisions based on the thorough
                    analysis of your business data.
                  </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Experience the power of data-driven excellence, unlocking
                    new possibilities for your business growth.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="services__content">
          <i className="uil uil-web-grid services__icon"></i>
          <h3 className="services__title">
            Portfolio <br /> Design and Development
          </h3>

          <span className="services__button" onClick={() => toggleTab(1)}>
            View More
            <i className="uil uil-arrow-right services__button-icon"></i>
          </span>

          <div
            className={
              toggleState === 1
                ? "services__modal active-modal"
                : "services__modal"
            }
          >
            <div className="services__modal-content">
              <i
                onClick={() => toggleTab(0)}
                className="uil uil-times services__modal-close"
              ></i>

              <h3 className="services__modal-title">
                Portfolio Design and Development
              </h3>
              <p className="services__modal-description">
                Elevate your online presence with custom Portfolio Design and
                Development. Crafted with precision and creativity, our
                solutions showcase your unique brand, skills, and achievements,
                leaving a lasting impression on your audience. Let your
                portfolio speak volumes about your expertise and
                professionalism.
              </p>

              <ul className="services__modal-services grid">
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Crafting Intuitive User Interfaces.
                  </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Building Dynamic and Responsive Web Pages.
                  </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Pioneering UX Element Interactions.
                  </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Strategic Company Brand Positioning.
                  </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Innovating Product Design and Mockups for Businesses.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="services__content">
          <i className="uil uil-arrow services__icon"></i>
          <h3 className="services__title">
            Website <br /> Development
          </h3>

          <span className="services__button" onClick={() => toggleTab(2)}>
            View More
            <i className="uil uil-arrow-right services__button-icon"></i>
          </span>

          <div
            className={
              toggleState === 2
                ? "services__modal active-modal"
                : "services__modal"
            }
          >
            <div className="services__modal-content">
              <i
                onClick={() => toggleTab(0)}
                className="uil uil-times services__modal-close"
              ></i>

              <h3 className="services__modal-title">Website Development</h3>
              <p className="services__modal-description">
                Unlock the potential of your online presence with our expert
                Website Development services. From dynamic web pages to seamless
                user interfaces, we tailor solutions that captivate audiences
                and elevate your digital footprint. Let us bring your vision to
                life, ensuring a responsive, engaging, and user-friendly website
                that leaves a lasting impression.
              </p>

              <ul className="services__modal-services grid">
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Tailor-made website development services to meet your unique
                    needs.
                  </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Create engaging and interactive web pages for a standout
                    online experience.
                  </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Craft user interfaces that are intuitive, enhancing overall
                    usability.
                  </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Elevate your online footprint for increased visibility and
                    reach.
                  </p>
                </li>

                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Ensure your website is accessible and user-friendly across
                    all devices.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="help_btn" data-aos="fade-up">
        <Link className="btn-contact" to="/contact">
          <span>Reach Me Today</span>
          <i></i>
        </Link>
      </div>
    </section>
  );
};

export default ServiceSection;
