import DataScience from "../../Assets/Images/Data_Science.jpg";
import WebDevelopment from "../../Assets/Images/Web_Development.jpg";
import BusinessAnalytics from "../../Assets/Images/Business_Analytics.jpg";

export const projects = [
  {
    title: "Web Development",
    description: "My Web Development Projects",
    image: WebDevelopment,
    path: "/devProjects",
  },
  {
    title: "Data Analysis/Science",
    description: "My Data Analysis/Science Projects",
    image: DataScience,
    path: "/dataProjects",
  },
  {
    title: "Business Analytics",
    description: "My Business Analytics Projects",
    image: BusinessAnalytics,
    path: "/businessProjects",
  },
];
