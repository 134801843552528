import React from "react";

import "./Footer.css";
import "./QueryFooter.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <h1 className="footer__title">
          <span>M</span>anoj <span>C</span> N
        </h1>

        <ul className="footer__list">
          <li>
            <Link to="/about" className="footer__link">
              About
            </Link>
          </li>
          <li>
            <Link to="/skills" className="footer__link">
              My Skills
            </Link>
          </li>

          <li>
            <Link to="/projects" className="footer__link">
              Projects
            </Link>
          </li>

          <li>
            <Link to="/myblog" className="footer__link">
              My Blog
            </Link>
          </li>
          <li>
            <Link to="/contact" className="footer__link">
              Contact
            </Link>
          </li>
        </ul>

        <div className="footer__social">
          <Link
            to="https://www.linkedin.com/in/manoj-c-n-b50273198/"
            className="footer__social-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bx bxl-linkedin"></i>
          </Link>
          <Link
            to="https://github.com/ManojCN20"
            className="footer__social-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bx bxl-github"></i>
          </Link>

          <Link
            to="https://www.facebook.com/manoj.cn.752/"
            className="footer__social-link"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bx bxl-facebook"></i>
          </Link>
          <Link
            to="https://instagram.com/manuinchu"
            className="footer__social-link"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bx bxl-instagram"></i>
          </Link>
        </div>

        <span className="footer__copy">
          <p>&#169; 2024 Manthya. All Rights Reserved</p>
          <p>Design and Developed by Manthya Portfolio and Analytics</p>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
